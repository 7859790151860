import React from "react";
import loadable from "@loadable/component";
import RelatedPosts from "../../Related/RelatedPosts";
import RelatedProjects from "../../Related/RelatedProjects";
export const PostFeed = (props) => {
  if (props.contentType === "project") {
    return <RelatedProjects {...props} />;
  }
  if (props.contentType === "post") {
    return <RelatedPosts {...props} />;
  }
  return null;
};
