import React, { useState } from "react";
import "./IndustrySelector.scss";
import { ReactComponent as Arrow } from "images/icons/arrow.svg";
import { GatsbyLink } from "components";
import { RenderShortcode } from "components/RenderShortcode";
import { ImagePass } from "components/Image/ImagePass";

export const IndustrySelector = ({ content, industries }) => {
  // on load sets feature image to the first item in menu builder
  const [featureImage, setFeatureImage] = useState(0);

  return (
    <section className="industry-selector layout">
      <div className="industry-selector-container">
        <div className="col content">
          <RenderShortcode content={content} />
        </div>
        <div className="col industry-boxes">
          {Object.keys(industries).length > 0 &&
            industries?.map((industry, index) => {
              return (
                <GatsbyLink
                  to={industry.link.url}
                  className="industry-box"
                  key={index}
                  onMouseEnter={() => {
                    setFeatureImage(index);
                  }}
                >
                  <span className="typography-h3">{industry?.name}</span>
                  <span className="arrow">
                    <Arrow />
                  </span>
                </GatsbyLink>
              );
            })}
        </div>
      </div>
      <div className={`industry-selector-image image${featureImage}-visible`}>
        {Object.keys(industries).length > 0 &&
          industries?.map((industry, index) => {
            return <ImagePass src={industry?.image} key={index} />;
          })}
      </div>
    </section>
  );
};

IndustrySelector.defaultProps = {
  backgroundColour: "white",
};
