import React from "react";
import "./LogoGallery.scss";
import { RenderShortcode } from "../../RenderShortcode";
import { Carousel } from "components";

export const LogoGallery = ({ content, backgroundColour, logos }) => {
  const carouselOptions = {
    perView: 3,
    type: "slider",
    gap: 30,
    swipeThreshold: true,
    breakpoints: {
      1080: {
        perView: 2,
      },
      700: {
        perView: 1,
        gap: 0,
      },
    },
  };

  return (
    <section className={`logo-gallery layout ${backgroundColour}`}>
      <div className="logo-gallery-container">
        <div className="col content">
          <RenderShortcode content={content} />
        </div>
      </div>

      <div className="carousel-container">
        <Carousel
          options={carouselOptions}
          id="logo-gallery"
          theme={backgroundColour}
        >
          {Object.keys(logos).length > 0 &&
            logos?.map((logo, index) => {
              return logo.link ? (
                <a
                  href={logo.link}
                  target="_blank"
                  rel="noreferrer"
                  className="image-container"
                  key={index}
                >
                  {logo?.logo?.source_url ? (
                    <img src={logo.logo.source_url} alt={logo.logo.title} />
                  ) : null}
                </a>
              ) : (
                <div className="image-container" key={index}>
                  {logo?.logo?.source_url ? (
                    <img src={logo.logo.source_url} alt={logo.logo.title} />
                  ) : null}
                </div>
              );
            })}
        </Carousel>
      </div>
    </section>
  );
};
