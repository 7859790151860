import React from "react";
import "./TextBlock.scss";
import { RenderShortcode } from "../../RenderShortcode";

export const TextBlock = ({ leftContent, rightContent, backgroundColour }) => {
  return (
    <section className={`text-block layout ${backgroundColour}`}>
      <div className="text-block-container">
        <div className="col">
          <RenderShortcode content={leftContent} />
        </div>

        <div className="col">
          <RenderShortcode content={rightContent} />
        </div>
      </div>
    </section>
  );
};

TextBlock.defaultProps = {
  backgroundColour: "white",
};
