import React, { useCallback, useEffect, useRef } from "react";
import "./FourUpGallery.scss";
import { ImagePass } from "../../Image/ImagePass";
import { Carousel, GatsbyLink } from "components";
import { pad_with_zeroes } from "../../../utils/helpers";
import { ReactComponent as Arrow } from "images/icons/arrow.svg";
import { Player } from "@lottiefiles/react-lottie-player";

import construction from "./animations/construction.json";
import design from "./animations/design.json";
import fitout from "./animations/fitout.json";
import renovation from "./animations/renovation.json";

const Animation = ({ row, index }) => {
  const lottieRef = useRef(null);
  const el = useRef(null);

  // each key is related to the index of the map
  const animations = Object.freeze({
    0: design,
    1: fitout,
    2: construction,
    3: renovation,
  });

  const mouseEnter = useCallback(() => {
    lottieRef.current.setPlayerDirection("1");
    lottieRef.current.play();
  }, []);

  const mouseLeave = useCallback(() => {
    lottieRef.current.setPlayerDirection("-1");
    lottieRef.current.play();
  }, []);

  useEffect(() => {
    if (el.current) {
      const animationEl = el.current;
      animationEl.addEventListener("mouseenter", mouseEnter);
      animationEl.addEventListener("mouseleave", mouseLeave);
      return () => {
        if (animationEl) {
          animationEl.removeEventListener("mouseenter", mouseEnter);
          animationEl.removeEventListener("mouseleave", mouseLeave);
        }
      };
    }
  }, [mouseEnter, mouseLeave]);

  return (
    <div ref={el}>
      <GatsbyLink to={row.link.url} className="row">
        <Player
          src={animations[index]}
          style={{ height: "300px", width: "300px" }}
          ref={lottieRef}
          keepLastFrame={true}
        ></Player>
        <span className="eyebrow">{pad_with_zeroes(index + 1, 2)}</span>
        <h3 className="service-heading">{row.heading}</h3>
        <p>{row.description}</p>
        <span className="arrow">
          <Arrow />
        </span>
      </GatsbyLink>
    </div>
  );
};

export const FourUpGallery = ({
  heading,
  eyebrow,
  rows,
  animation,
  standalone,
  sectionClass,
}) => {
  const carouselOptions = {
    perView: 4,
    type: "slider",
    gap: 70,
    dragThreshold: false,
    swipeThreshold: true,
    bound: true,
    breakpoints: {
      1080: {
        perView: 2,
      },
      700: {
        perView: 1,
        gap: 30,
      },
    },
  };

  return (
    <section
      className={`four-up-gallery layout ${standalone ? "standalone" : ""} ${
        sectionClass ? sectionClass : ""
      }`}
    >
      <div className="four-up-gallery-container">
        <span className="eyebrow">{eyebrow}</span>
        <h2 className="heading">{heading}</h2>
      </div>
      <div className="carousel-container">
        <Carousel
          options={carouselOptions}
          id="service-selector"
          fullWidth
          theme="navy"
        >
          {Object.keys(rows).length > 0 &&
            rows?.map((row, index) => {
              if (animation) {
                return <Animation row={row} index={index} key={index} />;
              } else {
                return (
                  <div className="row" key={index}>
                    {row?.icon?.source_url && (
                      <img src={row?.icon?.source_url} className="icon" />
                    )}
                    {row?.heading && (
                      <h3 className="service-heading">{row?.heading}</h3>
                    )}
                    {row?.description && <p>{row?.description}</p>}
                  </div>
                );
              }
            })}
        </Carousel>
      </div>
    </section>
  );
};
