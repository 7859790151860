import React from "react";
import "./ContactForm.scss";
import { RenderShortcode } from "../../RenderShortcode";
import RenderContent from "../../RenderContent";

export const ContactForm = ({ googleMap, content, form }) => {
  return (
    <>
      <section className="contact-info layout">
        <div className="contact-info-container">
          <div className="contact-map">
            <RenderContent content={googleMap} />
          </div>
          <div className="col">
            <RenderShortcode content={content} />
          </div>
        </div>
      </section>
      <section className="contact-form layout">
        <div className="contact-form-container">
          <RenderShortcode content={form} />
        </div>
      </section>
    </>
  );
};

ContactForm.defaultProps = {
  backgroundColour: "white",
};
