import React, { useState } from "react";
import RenderContent from "../../RenderContent";
import { Helmet } from "react-helmet";
import "./FaqBlock.scss";

const AccordionItem = ({ content }) => {
  const [isOpen, setIsOpen] = useState(false);

  return content ? (
    <div className={`accordion-item ${isOpen ? "accordion-item--open" : ""}`}>
      <div
        className="accordion-header"
        onClick={() => setIsOpen((previousState) => !previousState)}
      >
        <h3 className="accordion-title">{content.heading}</h3>
        <div className="accordion-button">
          <svg
            width="14px"
            height="8px"
            viewBox="0 0 14 8"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Stroke 1 Copy 2</title>
            <g
              id="Page-1"
              stroke="none"
              strokeWidth="1"
              fill="none"
              fillRule="evenodd"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <g
                id="Cyclo---Template-5---Service---Fit-Out-(FAQ)"
                transform="translate(-1441.000000, -4421.000000)"
                stroke="#E9E61C"
                strokeWidth="2"
              >
                <polyline
                  id="Stroke-1-Copy-2"
                  transform="translate(1448.000000, 4425.000029) rotate(-270.000000) translate(-1448.000000, -4425.000029) "
                  points="1445 4419 1451 4425.00006 1445 4431.00006"
                ></polyline>
              </g>
            </g>
          </svg>
        </div>
      </div>
      <div className="content">
        {content.content ? <RenderContent content={content.content} /> : null}
      </div>
    </div>
  ) : null;
};

export const FaqBlock = ({ eyebrow, heading, rows, theme }) => {
  // maps through the content and returns it in the format of the schema
  const schemaContent = rows.map((faq) => {
    return {
      "@type": "Question",
      name: faq.heading,
      acceptedAnswer: {
        "@type": "Answer",
        text: faq.content,
      },
    };
  });

  const accordionSchema = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: [schemaContent],
  };

  return (
    <section
      className={`faq-block layout ${
        theme === "dark" ? "faq-block--dark" : null
      }`}
    >
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(accordionSchema)}
        </script>
      </Helmet>
      <div className="faq-block-container">
        <span className="eyebrow">{eyebrow}</span>
        <h2 className="heading">{heading}</h2>
        {Object.keys(rows).length > 0 &&
          rows?.map((item, index) => {
            return (
              <AccordionItem content={item} key={`accordionItem_${index}`} />
            );
          })}
      </div>
    </section>
  );
};
