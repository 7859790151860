import React from "react";
import { Button } from "components";
import RenderContent from "../../RenderContent";
import { ImagePass } from "../../Image/ImagePass";
import "./StaffProfiles.scss";

export const StaffProfiles = ({
  leftContent,
  rightContent,
  staffProfiles,
  button,
}) => {
  return (
    <section className={`staff-profiles layout ${button ? "section" : "page"}`}>
      <div className="staff-profiles-container">
        <div className="staff-profiles-header">
          <div className="col">
            <RenderContent content={leftContent} />
          </div>
          <div className="col">
            <RenderContent content={rightContent} />
          </div>
        </div>
        <div className="profiles">
          {staffProfiles &&
            staffProfiles.map((profile, index) => (
              <div className="profile" key={index}>
                <div className="image">
                  <div className="aspect">
                    <ImagePass src={profile.image} />
                  </div>
                </div>
                <p className="name">{profile.name}</p>
                {profile.position && (
                  <p className="position">{profile.position}</p>
                )}
              </div>
            ))}
        </div>
        {button && (
          <div className="button-container">
            <Button to={button.url} theme="light">
              {button.title}
            </Button>
          </div>
        )}
      </div>
    </section>
  );
};
