import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { Button, GatsbyLink } from "components";
import { decodeEntities } from "../../utils/helpers";
import RenderContent from "../RenderContent";
import { dateI18n } from "@wordpress/date";
import "./RelatedPosts.scss";

const RelatedPostComp = ({ data, title, backgroundColour }) => {
  const {
    allWordpressPost,
    wordpressWpSettings: { blogSlug, date_format: dateFormat },
    // siteSettings,
  } = data;
  const { edges: posts } = allWordpressPost;
  const blogFeedLink = {
    url: `/${blogSlug}/`,
    title: "View all",
  };
  return (
    <section className={`post-feed layout ${backgroundColour}`}>
      <div className="post-feed-container">
        <p className="eyebrow">Blog</p>
        <h2>{title}</h2>

        <div className="post-list">
          {Object.keys(posts).length > 0 &&
            posts?.map((post, index) => {
              const postNode = post?.node;
              return (
                <GatsbyLink to={postNode.path} className="post" key={index}>
                  <div>
                    <span className="eyebrow">
                      {dateI18n(dateFormat, postNode.date)}
                    </span>

                    <h3>{decodeEntities(postNode.title)}</h3>
                  </div>
                  <div>
                    <RenderContent content={postNode.excerpt} />
                  </div>
                </GatsbyLink>
              );
            })}
        </div>

        {blogFeedLink && (
          <div className="button-contianer">
            <Button to={blogFeedLink.url}>{blogFeedLink.title}</Button>
          </div>
        )}
      </div>
    </section>
  );
};

const RelatedPosts = (props) => {
  return (
    <StaticQuery
      //Look to update this query when dynamic variables become available https://spectrum.chat/gatsby-js/general/using-variables-in-a-staticquery~abee4d1d-6bc4-4202-afb2-38326d91bd05
      query={graphql`
        query {
          wordpressWpSettings {
            blogSlug
            date_format
          }
          siteSettings: wordpressAcfOptions(options: {}) {
            options {
              showAuthor
            }
          }
          categoryFilter: allWordpressPost {
            nodes {
              categories {
                wordpress_id
                id
                name
                slug
                id
              }
            }
          }
          allWordpressPost(sort: { fields: date, order: DESC }, limit: 4) {
            edges {
              node {
                ...PostListFields
              }
            }
          }
        }
      `}
      render={(data) => <RelatedPostComp {...props} data={data} />}
    />
  );
};

export default RelatedPosts;
