import React from "react";
import "./TwoCard.scss";
import { Button, GatsbyLink } from "components";
import { RenderShortcode } from "../../RenderShortcode";
import { Image } from "../../Image";

export const TwoCard = ({ cards }) => {
  return (
    <section className="two-card layout">
      <div className="two-card-container">
        {Object.keys(cards).length > 0 &&
          cards?.map((card, index) => {
            return (
              <GatsbyLink to={card.link.url} key={index} className="card">
                <span className="eyebrow">{card.eyebrow}</span>
                <h2 className="heading">{card.heading}</h2>
                <div className="content">
                  <RenderShortcode content={card.content} />
                </div>
                <div className="button">{card.link.title}</div>
                <div className="image-container">
                  <Image src={card.image} />
                </div>
              </GatsbyLink>
            );
          })}
      </div>
    </section>
  );
};
