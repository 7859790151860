import React, { useState, useEffect } from "react";
import "./Accordion.scss";
import { ImagePass } from "../../Image/ImagePass";
import { RenderShortcode } from "../../RenderShortcode";
import { pad_with_zeroes } from "utils/helpers";
import { ReactComponent as ChevronLeft } from "images/icons/chevron-left.svg";
import { ReactComponent as ChevronRight } from "images/icons/chevron-right.svg";
import Glide from "@glidejs/glide";
import "@glidejs/glide/dist/css/glide.core.min.css";
import "@glidejs/glide/dist/css/glide.theme.min.css";

export const Accordion = ({ introContent, tabs, sectionClass }) => {
  const carouselOptions = {
    perView: 1,
    type: "slider",
    rewind: false,
    dragThreshold: false,
    swipeThreshold: true,
    startAt: 0,
    gap: 0,
  };
  let glide = new Glide(".glide-accordion", carouselOptions);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [slideCount, setSlideCount] = useState(null);

  const handleNavigationClick = (slide_index) => {
    glide.update({
      startAt: slide_index,
    });
    glide.mount();
    setCurrentSlide(glide.index);
  };

  useEffect(() => {
    glide.mount();

    setSlideCount(tabs.length);

    return () => glide.destroy();
  }, []);

  glide.on("run.after", () => {
    setCurrentSlide(glide.index);
  });

  return (
    <section className={`accordion layout ${sectionClass ? sectionClass : ""}`}>
      <div className="accordion-container">
        <div className="intro">
          <RenderShortcode content={introContent} />
        </div>

        <div className="accordion-inner">
          {/* outputs all the headings and then creates an eventlistener. Which then navigates to that slide */}
          <div className="accordion-navigation">
            {Object.keys(tabs).length > 0 &&
              tabs?.map((tab, index) => {
                return (
                  <div
                    key={`navigation-key-${index}`}
                    className={`accordion-navigation-item ${
                      currentSlide === index ? "active" : ""
                    }`}
                    onClick={() => handleNavigationClick(index)}
                  >
                    <span className="eyebrow">
                      {pad_with_zeroes(index + 1, 2)}
                    </span>
                    <p className="typography-h3">{tab.heading}</p>
                  </div>
                );
              })}
          </div>

          <div className="accordion-content">
            <div className="glide navy glide-accordion">
              <div className="glide__arrows" data-glide-el="controls">
                <button
                  className="glide__arrow glide__arrow--left"
                  data-glide-dir="<"
                >
                  <ChevronLeft />
                </button>
              </div>
              <div className="glide__arrows" data-glide-el="controls">
                <button
                  className="glide__arrow glide__arrow--right"
                  data-glide-dir=">"
                >
                  <ChevronRight />
                </button>
              </div>
              {/* Close arrows */}

              <div className="glide__track" data-glide-el="track">
                <ul className="glide__slides">
                  {Object.keys(tabs).length > 0 &&
                    tabs?.map((tab, index) => {
                      return (
                        <div key={index} className="accordion-slide">
                          <div className="image-container">
                            <ImagePass src={tab.image} />
                          </div>
                          <span className="eyebrow">
                            {pad_with_zeroes(index + 1, 2)} /{" "}
                            {pad_with_zeroes(slideCount, 2)}
                          </span>
                          <h3 className="slide-heading">{tab.heading}</h3>
                          <p className="slide-content">{tab.content}</p>
                        </div>
                      );
                    })}
                </ul>
              </div>
              {/* Close slides */}

              <span className="current-indicator">
                {pad_with_zeroes(currentSlide + 1, 2)} /{" "}
                {pad_with_zeroes(slideCount, 2)}
              </span>
              {/* Close current position indicator */}

              <div className="glide__bullets" data-glide-el="controls[nav]">
                {Object.keys(tabs).length > 0 &&
                  tabs?.map((bullet, index) => {
                    return (
                      <button
                        className="glide__bullet"
                        data-glide-dir={`=${index}`}
                        key={index}
                      ></button>
                    );
                  })}
              </div>
              {/* Close bullets */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
