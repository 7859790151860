import React from "react";
import { ImagePass } from "../../Image/ImagePass";
import { ReactComponent as LineDetail } from "./line-detail.svg";
import "./FullWidthImage.scss";

export const FullWidthImage = ({ image }) => {
  return (
    <section className="full-image layout">
      <div className="full-image-container">
        <div className="image-container">
          <ImagePass src={image} />
          <div className="line-detail">
            <LineDetail />
          </div>
        </div>
      </div>
    </section>
  );
};
